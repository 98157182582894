<ion-app dir="{{ textDir }}">
  <!-- when=false means that the split pane will never expand automatically -->
  <!-- For more info check https://ionicframework.com/docs/api/split-pane -->
  <ion-split-pane contentId="menu-content" when="false">
    <ion-menu contentId="menu-content" class="app-sidemenu">
      <ion-header>
        <ion-toolbar>
          <ion-row class="user-details-wrapper">
            <ion-col size="3">
              <app-aspect-ratio [ratio]="{ w: 1, h: 1 }">
                <app-image-shell
                  *ngIf="!profileData.photoURL"
                  class="user-avatar"
                  animation="spinner"
                  [src]="'https://gemini-global.com/assets/themes_logo.jpg'"
                ></app-image-shell>

                <app-image-shell
                  *ngIf="profileData.photoURL"
                  class="user-avatar"
                  animation="spinner"
                  [src]="profileData.photoURL"
                ></app-image-shell>
              </app-aspect-ratio>
            </ion-col>
            <ion-col class="user-info-wrapper" size="8">
              <h3 class="user-name">{{ profileData.displayName }}</h3>
              <h5 class="user-handle">{{ profileData.email }}</h5>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <!--
          <ion-list-header>
            <ion-label>Job Search</ion-label>
          </ion-list-header>
          -->
          <ion-menu-toggle
            autoHide="false"
            *ngFor="let p of appPages; let i = index"
          >
            <ion-item [routerLink]="p.url">
              <ion-icon
                slot="start"
                [name]="p.ionicIcon ? p.ionicIcon : ''"
                [src]="p.customIcon ? p.customIcon : ''"
              ></ion-icon>
              <ion-label>
                {{ p.title }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <!-- Main app content get's rendered in this router-outlet -->
    <ion-router-outlet id="menu-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
