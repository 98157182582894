import { Component } from "@angular/core";
import { Plugins } from "@capacitor/core";
import { SeoService } from "./utils/seo/seo.service";
const { SplashScreen } = Plugins;
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { HistoryHelperService } from "./utils/history-helper.service";
import { CommonRequestService } from "./utils/common-request.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: [
    "./side-menu/styles/side-menu.scss",
    "./side-menu/styles/side-menu.shell.scss",
    "./side-menu/styles/side-menu.responsive.scss",
  ],
})
export class AppComponent {
  profileData: any = {};
  appPages = [
    {
      title: this.translate.instant("Job search"),
      url: "/app/search",
      ionicIcon: "briefcase-outline",
    },
    {
      title: this.translate.instant("Latest jobs"),
      url: "/app/latestjobs",
      ionicIcon: "business-outline",
    },
    {
      title: this.translate.instant("Notifications"),
      url: "/app/notifications",
      ionicIcon: "notifications-outline",
    },
    {
      title: this.translate.instant("Profile"),
      url: "/app/profile",
      ionicIcon: "person-outline",
    },
    {
      title: this.translate.instant("Logout"),
      url: "/auth/profile",
      ionicIcon: "log-out-outline",
    },
  ];
  accountPages = [
    {
      title: this.translate.instant("Contact Card"),
      url: "/contact-card",
      customIcon: "./assets/custom-icons/side-menu/contact-card.svg",
    },
    {
      title: this.translate.instant("Log In"),
      url: "/auth/login",
      ionicIcon: "log-in-outline",
    },
    {
      title: this.translate.instant("Sign Up"),
      url: "/auth/signup",
      ionicIcon: "person-add-outline",
    },
    {
      title: this.translate.instant("Tutorial"),
      url: "/walkthrough",
      ionicIcon: "school-outline",
    },
    {
      title: this.translate.instant("Getting Started"),
      url: "/getting-started",
      ionicIcon: "rocket-outline",
    },
    {
      title: this.translate.instant("404 page"),
      url: "/page-not-found",
      ionicIcon: "alert-circle-outline",
    },
  ];

  textDir = "ltr";

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    private seoService: SeoService,
    private commonService: CommonRequestService
  ) {
    this.initializeApp();
    this.setLanguage();

    this.commonService.getuserData.subscribe((data) => {
      if (this.commonService.getSession("user_info")) {
        this.profileData = JSON.parse(
          this.commonService.getSession("user_info")
        );
      }
    });
  }

  async initializeApp() {
    try {
      await SplashScreen.hide();
    } catch (err) {
      console.log("This is normal in a browser", err);
    }
  }

  setLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang("en");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (this.commonService.getSession("my_language")) {
      this.translate.use(this.commonService.getSession("my_language"));
    } else {
      this.translate.use("en");
    }

    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });
  }
}
