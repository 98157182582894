import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
} from "@angular/fire/auth-guard";

// redirect loggedin user to search page..
const redirectLoggedInUser = () => redirectLoggedInTo(["app"]);

const routes: Routes = [
  {
    path: "",
    redirectTo: "/walkthrough",
    pathMatch: "full",
  },
  {
    path: "walkthrough",
    loadChildren: () =>
      import("./walkthrough/walkthrough.module").then(
        (m) => m.WalkthroughPageModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInUser },
  },
  {
    path: "getting-started",
    loadChildren: () =>
      import("./getting-started/getting-started.module").then(
        (m) => m.GettingStartedPageModule
      ),
  },
  {
    path: "search",
    redirectTo: "app/search",
    pathMatch: "full",
  },
  {
    path: "auth",
    redirectTo: "auth/login",
    pathMatch: "full",
  },
  {
    path: "auth/login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInUser },
  },
  {
    path: "auth/signup",
    loadChildren: () =>
      import("./signup/signup.module").then((m) => m.SignupPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInUser },
  },
  {
    path: "auth/forgot-password",
    loadChildren: () =>
      import("./forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInUser },
  },
  {
    path: "app",
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
  },
  {
    path: "forms-and-validations",
    loadChildren: () =>
      import("./forms/validations/forms-validations.module").then(
        (m) => m.FormsValidationsPageModule
      ),
  },
  /*
  {
    path: 'notification-settings',
    loadChildren: () => import('./notification-settings/filters/forms-filters.module').then(m => m.FormsFiltersPageModule)
  },
  */
  {
    path: "forms-filters",
    loadChildren: () =>
      import("./forms/filters/forms-filters.module").then(
        (m) => m.FormsFiltersPageModule
      ),
  },
  {
    path: "page-not-found",
    loadChildren: () =>
      import("./page-not-found/page-not-found.module").then(
        (m) => m.PageNotFoundModule
      ),
  },
  {
    path: "sign-in",
    loadChildren: () =>
      import("./firebase/auth/sign-in/firebase-sign-in.module").then(
        (m) => m.FirebaseSignInPageModule
      ),
  },
  {
    path: "sign-up",
    loadChildren: () =>
      import("./firebase/auth/sign-up/firebase-sign-up.module").then(
        (m) => m.FirebaseSignUpPageModule
      ),
  },
  {
    path: "auth/profile",
    loadChildren: () =>
      import("./firebase/auth/profile/firebase-profile.module").then(
        (m) => m.FirebaseProfilePageModule
      ),
  },
  {
    path: "auth/delete",
    loadChildren: () =>
      import("./firebase/auth/profile-delete/delete-profile.module").then(
        (m) => m.DeleteProfilePageModule
      ),
  },
  {
    path: "firebase/auth",
    loadChildren: () =>
      import("./firebase/auth/firebase-auth.module").then(
        (m) => m.FirebaseAuthModule
      ),
  },
  {
    path: "**",
    redirectTo: "page-not-found",
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // This value is required for server-side rendering to work.
      initialNavigation: "enabled",
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
