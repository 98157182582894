// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
/*
  firebase: {
    apiKey: 'AIzaSyC5QK355uuknu0_ldVxFqqNqgp9oJi_eLc',
    authDomain: 'ion4fullpwa.firebaseapp.com',
    databaseURL: 'https://ion4fullpwa.firebaseio.com',
    projectId: 'ion4fullpwa',
    storageBucket: 'ion4fullpwa.appspot.com'
  },

  firebase: {
    apiKey: "AIzaSyBQp6zFTWTJ67lukhJdCZprv14zYBbbJX8",
    authDomain: "geminipersonnelapp.firebaseapp.com",
    databaseURL: "https://geminipersonnelapp.firebaseio.com",
    projectId: "geminipersonnelapp",
    storageBucket: "geminipersonnelapp.appspot.com"
  },
*/
  firebase: {
    apiKey: "AIzaSyBvMMCYePqgiEQ1pCp5p_rTO9kvipnLnCs",
    authDomain: "geminijobsearch.firebaseapp.com",
    projectId: "geminijobsearch",
    storageBucket: "geminijobsearch.appspot.com",
    messagingSenderId: "752087593137",
    appId: "1:752087593137:web:70ee93795550010c6f3290",
    measurementId: "G-3J7SD4BHJB"
  },

  appShellConfig: {
    debug: false,
    networkDelay: 500
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
