import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable, Subject, from, of } from "rxjs";
import { DataStore } from "../../shell/data-store";
import { FirebaseProfileModel } from "./profile/firebase-profile.model";
import { Platform } from "@ionic/angular";
import { filter, map } from "rxjs/operators";

import { User, auth } from "firebase/app";
import { cfaSignIn, cfaSignOut } from "capacitor-firebase-auth";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class FirebaseAuthService {
  currentUser: User;
  userProviderAdditionalInfo: any;
  profileDataStore: DataStore<FirebaseProfileModel>;
  redirectResult: Subject<any> = new Subject<any>();

  constructor(
    public angularFire: AngularFireAuth,
    public platform: Platform,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.angularFire.onAuthStateChanged((user) => {
        if (user) {
          // User is signed in.
          console.log("User is signed in");
          this.currentUser = user;
        } else {
          // No user is signed in.
          console.log("No user is signed in");
          this.currentUser = null;
        }
      });

      if (!this.platform.is("capacitor")) {
        // when using signInWithRedirect, this listens for the redirect results
        this.angularFire.getRedirectResult().then(
          (result) => {
            // result.credential.accessToken gives you the Provider Access Token. You can use it to access the Provider API.
            if (result.user) {
              this.userProviderAdditionalInfo =
                result.additionalUserInfo.profile;
              this.redirectResult.next(result);
            }
          },
          (error) => {
            this.redirectResult.next({ error: error.code });
          }
        );
      }
    }
  }

  getRedirectResult(): Observable<any> {
    return this.redirectResult.asObservable();
  }

  getPhotoURL(signInProviderId: string, photoURL: string): string {
    // Default imgs are too small and our app needs a bigger image
    switch (signInProviderId) {
      case "facebook.com":
        return photoURL + "?height=400";
      case "password":
        return "https://gemini-global.com/assets/avatar-placeholder.png";
      case "twitter.com":
        return photoURL.replace("_normal", "_400x400");
      case "google.com":
        return photoURL.split("=")[0];
      default:
        return photoURL;
    }
  }

  signOut(): Observable<any> {
    if (this.platform.is("capacitor")) {
      return cfaSignOut();
    } else {
      return from(this.angularFire.signOut());
    }
  }

  signInWithEmail(
    email: string,
    password: string
  ): Promise<auth.UserCredential> {
    return this.angularFire.signInWithEmailAndPassword(email, password);
  }

  signUpWithEmail(
    email: string,
    password: string
  ): Promise<auth.UserCredential> {
    return this.angularFire.createUserWithEmailAndPassword(email, password);
  }

  socialSignIn(providerName: string, scopes?: Array<string>): Observable<any> {
    if (this.platform.is("capacitor")) {
      return cfaSignIn(providerName);
    } else {
      const provider = new auth.OAuthProvider(providerName);
      if (scopes) {
        scopes.forEach((scope) => {
          provider.addScope(scope);
        });
      }

      if (this.platform.is("ios")) {
        console.log("I am an iOS device!");
      }
      if (this.platform.is("android")) {
        console.log("I am an android device!");
      }
      if (this.platform.is("desktop")) {
        console.log("I am an desktop device!");
      }
      if (this.platform.is("electron")) {
        console.log("I am an electron device!");
      }
      if (this.platform.is("hybrid")) {
        console.log("I am an hybrid device!");
      }
      if (this.platform.is("mobile")) {
        console.log("I am an mobile device!");
      }
      if (this.platform.is("mobileweb")) {
        console.log("I am an mobileweb device!");
      }
      if (this.platform.is("pwa")) {
        console.log("I am an pwa device!");
      }

      if (
        this.platform.is("desktop") ||
        this.platform.is("mobile") ||
        this.platform.is("mobileweb")
      ) {
        console.log("desktop or  mobileweb");
        return from(this.angularFire.signInWithPopup(provider));
      } else {
        // web but not desktop, for example mobile PWA
        console.log("other platforms");
        return from(this.angularFire.signInWithRedirect(provider));
      }
    }
  }

  signInWithFacebook() {
    const provider = new auth.FacebookAuthProvider();
    return this.socialSignIn(provider.providerId);
  }

  signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    const scopes = ["profile", "email"];
    return this.socialSignIn(provider.providerId, scopes);
  }

  signInWithTwitter() {
    const provider = new auth.TwitterAuthProvider();
    return this.socialSignIn(provider.providerId);
  }

  public getProfileDataSource(): Observable<FirebaseProfileModel> {
    // we need to do this differentiation because there is a bug in
    // platform capacitor ios when executing this.angularFire.user
    if (this.platform.is("capacitor")) {
      return of(this.setUserModelForProfile());
    } else {
      return this.angularFire.user.pipe(
        filter((user: User) => user != null),
        map((user: User) => {
          return this.setUserModelForProfile();
        })
      );
    }
  }

  private setUserModelForProfile(): FirebaseProfileModel {
    const userModel = new FirebaseProfileModel();
    const provierData = this.currentUser.providerData[0];
    const userData = this.userProviderAdditionalInfo
      ? this.userProviderAdditionalInfo
      : provierData;
    userModel.image = this.getPhotoURL(
      provierData.providerId,
      provierData.photoURL
    );
    userModel.name =
      userData.name || userData.displayName || "Gemini Personnel";
    userModel.role = "LEADING RECRUITMENT ACROSS ASIA";
    userModel.description =
      userData.description || "Thank you for using our Job Search App";
    userModel.phoneNumber =
      userData.phoneNumber || "Is there a number where I can reach you?";
    userModel.email = userData.email || "Where can I send you emails?";
    userModel.provider =
      provierData.providerId !== "password"
        ? provierData.providerId
        : "Credentials";

    return userModel;
  }

  public getProfileStore(
    dataSource: Observable<FirebaseProfileModel>
  ): DataStore<FirebaseProfileModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: FirebaseProfileModel = new FirebaseProfileModel();
    this.profileDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.profileDataStore.load(dataSource);
    return this.profileDataStore;
  }
}
