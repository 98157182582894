import { Injectable, Inject } from "@angular/core";
import { throwError, BehaviorSubject } from "rxjs";
import { formatDate } from "@angular/common";
import { ToastController, LoadingController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";


@Injectable({
  providedIn: "root",
})
export class CommonRequestService {
  loaderToShow: any;
  myDatas: any;

  private profileData = new BehaviorSubject<object>({});
  public getuserData = this.profileData.asObservable();

  constructor(
    public toastController: ToastController,
    public loadingController: LoadingController,
    public translate: TranslateService
  ) {}

  updateUserData(content: any) {
    this.profileData.next(content);
  }

  async error_msg(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2500,
      color: "danger",
      animated: true,
    });
    toast.present();
  }

  async success_msg(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2500,
      animated: true,
      color: "success",
    });
    toast.present();
  }

  showLoading() {
    this.loaderToShow = this.loadingController
      .create({
        spinner: "circles",
        duration: 7000,
        message: this.translate.instant("Please Wait..."),
        translucent: true,
        cssClass: "custom-class custom-loading",
        backdropDismiss: false,
      })
      .then((res) => {
        res.present();
      });
  }

  hideLoading() {
    setTimeout(() => {
      this.loadingController.dismiss();
    }, 100);
  }

  // Error handling
  handleError(error: {
    error: { message: string };
    status: any;
    message: any;
  }) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.show_network_err(
      this.translate.instant("Network error occured. Please try later.")
    );
    return throwError(errorMessage);
  }

  // show network error
  show_network_err(msg: string) {
    alert(msg);
  }

  log(response: any, show = "Yes") {
    if (show === "Yes") {
      console.log(response);
    }
  }

  saveSession(key: any, val: any) {
    try {
      localStorage.setItem(key, val);
    } catch (e) {
      console.error("Error saving to localStorage", e);
    }
  }

  getSession(key: any) {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.error("Error getting data from localStorage", e);
      return null;
    }
  }

  destroySession(key) {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      console.error("Error removing data from localStorage", e);
      return null;
    }
  }

  am_i_online() {
    if (
      typeof this.getSession("user_info") !== "undefined" &&
      this.getSession("user_info") !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  getToken() {
    const user = JSON.parse(this.getSession("user_info"));
    if (user !== null) {
      return user.stsTokenManager.accessToken;
    } else {
      return null;
    }
  }

  getUID() {
    const user = JSON.parse(this.getSession("user_info"));
    if (user !== null) {
      return user.uid;
    } else {
      return null;
    }
  }

  getDataType(input) {
    return typeof input;
  }

  getFormattedDate(value, defaultFormat = "yyyy-MM-dd") {
    return formatDate(value, defaultFormat, "en");
  }

  checkObjectIsEmpty(obj) {
    return obj === null || undefined
      ? true
      : (() => {
          for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
              return false;
            }
          }
          return true;
        })();
  }

  checkArrayIsEmpty(array) {
    if (typeof array !== "undefined" && array.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  // convert object to formdata query string
  objectToQuerystring(params: {}) {
    return Object.keys(params)
      .map((key) => key + "=" + encodeURIComponent(params[key]))
      .join("&");
  }

  is_numeric(str) {
    return /^\d+$/.test(str);
  }

  nullUndefToBlank(value) {
    return value == null ? "" : value;
  }

  jsonToTypescriptArray(jsonData) {
    let jsonToBeUsed = [];

    for (var type in jsonData) {
      const item: any = {};
      item.key = type;
      item.value = jsonData[type];
      jsonToBeUsed.push(item);
    }
    return jsonToBeUsed;
  }

  strtoArr(myString) {
    let arr = [];
    if (myString != "") {
      arr = myString.split(",").map(Number);

      // arr = myString.split(",").map(function (item) {
      //   return parseInt(item, 10);
      // });
    }
    return arr;
  }

  public setData(data) {
    this.myDatas = data;
  }

  public getData() {
    return this.myDatas;
  }

  makeID(length) {
    const date = new Date().valueOf();
    let text = "";
    const possibleText =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }

    return date + "." + text;
  }
}
